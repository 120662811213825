'use strict';

/**
 * @ngdoc directive
 * @name ng-animate.directive:animate
 * @restrict A
 * @param {animate} execute when element is scrolled into viewport
 * @param {delayPercent=} percentage (of px) to delay animate when visible transition.
 * @param {bindScrollTo=} override default scroll event binding to another parent container.
 *
 * @description
 * Allows method hooks into the detection of when an element is scrolled into or out of view.
 *
 */

angular.module('ng-animate', []).directive('animate', ['$document', '$window',
    function($document, $window) {

        var determineWhereElementIsInViewport =
            function($el, viewportHeight, delayPercent, parallax, scope) {

                var _offset = $el.data('offset');

                var elementBounds = $el[0].getBoundingClientRect();

                // pixel buffer until deciding to show the element
                //var delayPx = delayPercent * elementBounds.height;
                var delayPx = delayPercent * viewportHeight;

                var top = delayPx;
                var bottom = viewportHeight - delayPx;
                var center = viewportHeight / 2;

                var panelTop = elementBounds.top - _offset;
                var panelBottom = elementBounds.bottom - _offset;
                var panelCenter = (panelTop + panelBottom) / 2;

                var topVisible = (panelTop > top) && (panelTop <= bottom);
                var bottomVisible = (panelBottom > top) && (panelBottom < bottom);
                var centerVisible = (panelTop < top) && (panelBottom > bottom);

                if(topVisible || bottomVisible || centerVisible) {
                    $el.addClass('visible');
                    $el.addClass('animated');
                    $el.removeClass('not-visible');
                }

                if(!topVisible && !bottomVisible && !centerVisible) {
                    $el.addClass('not-visible');
                    $el.removeClass('visible');
                }

                if(parallax) {
                    var position = (panelCenter - center) / viewportHeight;
                    if(position > 1) {
                        position = 1;
                    }
                    if(position < -1) {
                        position = -1
                    }

                    var offset = Math.round(position * viewportHeight/2 * parallax);

                    //$el[0].style.transform = 'translate3d(0px, ' + offset + 'px, 0)';
                    $el[0].style.transform = 'translateY(' + offset + 'px)';
                    $el.data('offset', offset);
                }

            };

        return {
            restrict: 'A',
            scope: {
                animate: '&',
                delayPercent: '=?',
                bindScrollTo: '@?',
                parallax: '=?'
            },

            controller: ['$scope', function(scope) {

                if(scope.delayPercent) {

                    var delayPercent = parseFloat(scope.delayPercent);

                    if(!angular.isNumber(delayPercent) || (delayPercent < 0 || delayPercent > 1)) {
                        throw new Error('Directive: ng-animate \'delay-percent\' attribute must be a decimal fraction between 0 and 1.');
                    }
                }
            }],

            link: function(scope, el, attributes) {

                var delayPercent = attributes.delayPercent || 0.10; // lower = more eager to hide / show, higher = less eager
                var parallax = attributes.parallax || 0.00; // higher = more swing
                var document = $document[0].documentElement;
                var checkPending = false;

                var updateVisibility = function() {
                    determineWhereElementIsInViewport(el, document.clientHeight /* viewportHeight */, delayPercent, parallax, scope);

                    checkPending = false;
                };

                var onScroll = function() {

                    if(!checkPending) {
                        checkPending = true;

                        /* globals requestAnimationFrame */
                        requestAnimationFrame(updateVisibility);
                    }
                };

                var documentListenerEvents = 'scroll';

                /* allows overflow:auto on container element to animate for Safari browsers */
                if(attributes.bindScrollTo) {
                    angular.element($document[0].querySelector(attributes.bindScrollTo)).on(documentListenerEvents, onScroll);
                }

                /* always bind to document scroll as well - works for overflow: auto on Chrome, Firefox browsers */
                $document.on(documentListenerEvents, onScroll);

                scope.$on('$destroy', function() {
                    $document.off(documentListenerEvents, onScroll);
                });

                var $elWindow = angular.element($window);
                var windowListenerEvents = 'resize orientationchange';
                $elWindow.on(windowListenerEvents, onScroll);

                scope.$on('$destroy', function() {
                    $elWindow.off(windowListenerEvents, onScroll);
                });

                // initialise
                el.addClass('animate not-visible');
                el.data('offset', 0);
                scope.$evalAsync(onScroll);
            }
        };
    }]);
